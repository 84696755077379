import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import { useStaticQuery, graphql } from "gatsby"

import SEO from "components/Seo"
import Layout from "components/Layout"

import * as S from "./styled"

const SobreMim = () => {
  const { avatar } = useStaticQuery(
    graphql`
      query {
        avatar: file(relativePath: { eq: "profile-photo.jpg" }) {
          childImageSharp {
            gatsbyImageData(layout: FIXED, width: 300, height: 300, quality: 100, placeholder: BLURRED)           
          }
        }
      }
    `
  )

  return (
    <Layout>
      <SEO title="Sobre Mim" />
      <S.Container>
        <GatsbyImage image={avatar.childImageSharp.gatsbyImageData} alt="Minha foto de perfil" />

        <S.ContainerTitles>
          <S.Title>Emanuel Andrade</S.Title>
          <S.Subtitle>Designer e Programador Frontend</S.Subtitle>
        </S.ContainerTitles>
        <S.ContainerContent>
          <S.Content>
            Em contato com Design Gráfico desde a adolescência, como passatempo,
            acabei me interesando profissionalmente e seguindo pela área. Foi
            quando durante o curso, em um estágio que envolvia criação de Design
            de Interfaces, comecei a pesquisar sobre programação e como colocar
            no ar as artes que estava criando.
          </S.Content>
          <S.Content>
            Desde então meu interesse pela programação foi aumentando, saindo do
            HTML & CSS puro e começando a aprender Javascript e PHP na época.
            Hoje em dia trabalho na área de desenvolvimento focado no front-end,
            principalmente com React e Vue.JS em conjunto com o Typescript. Por
            fora ainda tenho estudado um pouco de Node.JS e os diversos tipos de
            padrões de projeto afim de melhorar os meus códigos criados.
          </S.Content>
        </S.ContainerContent>
      </S.Container>

      {/* <S.Container id="contato">
        <S.ContatoTitle>Entre em contato comigo</S.ContatoTitle>
        <Contato />
      </S.Container> */}
    </Layout>
  )
}

export default SobreMim
