import styled from "styled-components"
import media from "styled-media-query"

export const Container = styled.article`
  background: var(--bg-color);
  padding: 20px 30px;
  box-shadow: 0 2px 12px #11101769;
  max-width: 800px;
  margin: 30px auto;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
  border-radius: 0.35rem;

  .gatsby-image-wrapper {
    width: 50%;
    border-radius: 0.75rem;
    box-shadow: 0 2px 12px #11101769;
  }

  ${media.lessThan("medium")`
  justify-content: center;
    text-align: center;
    max-width: 90%;
  `}
`


export const ContainerTitles = styled.section`
  width: 55%;

  ${media.lessThan("medium")`
    margin-top: 26px;  
    width: 90%;
  `}
`

export const Title = styled.h1`
  letter-spacing: 0.025em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-transform: uppercase;
  font-size: 3.7rem;
  color: var(--contrast-color);
  font-family: var(--fontSerif);
  line-height: 1;
  font-weight: 600;
  margin: 0;

  ${media.lessThan("large")`
    font-size: 2.8rem;
  `}
`

export const Subtitle = styled.h2`
  font-size: 1.7rem;
  color: var(--main-color);
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  line-height: 1.3;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: var(--fontSansSerif);

  ${media.lessThan("large")`
    font-size: 1.3rem;
    padding: 0 20px;
  `}
`

export const ContainerContent = styled.section`
  margin-top: 30px;
  display: flex;
  flex-flow: column wrap;
  justify-content: space-around;
  align-items: center;
  width: 100%;

  animation-name: appear;
  animation-duration: 200ms;

  @keyframes appear {
    0% {
      opacity: 0.1;
      transform: translate3d(0, -20px, 0);
    }
    100% {
      opacity: 1;
      transform: translate3d(0, 0, 0);
    }
  }

  ${media.lessThan("medium")`
    margin-top: 20px;
  `}
`

export const Content = styled.p`
  margin: 1rem 0;
  font-size: 1.15rem;
  color: var(--main-color);
  line-height: 1.55rem;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
`

export const ContatoTitle = styled.h3`
  width: 100%;
  color: var(--main-color);
`